@import '../../styles/default.scss', '../../styles/typography.scss';

header {
    width: 100%;
    margin-top: 28px;
    .transparent-btn {
        border: 1px solid $white;
        border-radius: 5px;
        width: 92px;
        margin-right: 16px;
        height: 38px;

        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $white;
        transition: 0.3s ease-in-out;
        &:hover {
            background-color: $white;
            color: $black;
        }
    }
    .header-btn {
        background: $brand-color-2;
        border-radius: 5px;
        height: 38px;
        width: 130px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $white;
        transition: 0.3s ease-in-out;
        border: 1px solid transparent;
        &:hover {
            background-color: transparent;
            border-color: $brand-color-2;
            color: $brand-color-2;
        }
    }
    img {
        height: 52px;
        mix-blend-mode: screen;
    }
}

@media(max-width: 991px) {
    header {
        .btns-container {
            display: none !important;
        }
    }
}
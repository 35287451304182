.mb-sm {
    margin-bottom: 16px;
}
.mb-md {
    margin-bottom: 24px;
}
.mb-lg {
    margin-bottom: 32px;
}
.mb-xlg {
    margin-bottom: 40px;
}
.mb-xxlg {
    margin-bottom: 48px;
}
.mb-xxxlg {
    margin-bottom: 56px;
}
.mb-xxxxlg {
    margin-bottom: 64px;
}

.mt-sm {
    margin-top: 16px;
}
.mt-md {
    margin-top: 24px;
}
.mt-lg {
    margin-top: 32px;
}
.mt-xlg {
    margin-top: 40px;
}
.mt-xxlg {
    margin-top: 48px;
}
.mt-xxxlg {
    margin-top: 64px;
}

.ml-sm {
    margin-left: 16px;
}
.ml-md {
    margin-left: 24px;
}
.ml-lg {
    margin-left: 32px;
}
.ml-xlg {
    margin-left: 40px;
}
.ml-xxlg {
    margin-left: 48px;
}
.ml-xxxlg {
    margin-left: 64px;
}

.mr-sm {
    margin-right: 16px;
}
.mr-md {
    margin-right: 24px;
}
.mr-lg {
    margin-right: 32px;
}
.mr-xlg {
    margin-right: 40px;
}
.mr-xxlg {
    margin-right: 48px;
}
.mr-xxxlg {
    margin-right: 64px;
}

.my-sm {
    margin: 16px 0px;
}
.my-md {
    margin: 24px 0px;
}
.my-lg {
    margin: 32px 0px;
}
.my-xlg {
    margin: 40px 0px;
}
.my-xxlg {
    margin: 48px 0px;
}
.my-xxxlg {
    margin: 64px 0px;
}